import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';

import '../Home.css';
import './NavigationBar.css';
import '../index.css';

function NavigationBar() {
  const [mostrarMenu, setMostrarMenu] = useState(false);

  const handleMostrarMenu = () => {
    setMostrarMenu(!mostrarMenu);
  };

  return (
    <navbar>
      <a href="/" className='lumiert'><img src='logo.png'/></a>
    </navbar>
  );
}

export default NavigationBar;
