import React, { useEffect, useState } from 'react';
import '../Home.css';

const ExternalRedirect = ({ url }) => {
  const [isRedirecting, setIsRedirecting] = useState(true);

  useEffect(() => {
    // Redireciona para o URL externo
    const timer = setTimeout(() => {
      window.location.href = url;
    }, 0); // Adiciona um pequeno atraso para mostrar a mensagem

    return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado
  }, [url]);

  if (!isRedirecting) {
    return null;
  }

  return (
    <div className='redirect-background'>
      <h1>Redirecionando...</h1>
    </div>
  );
};

export default ExternalRedirect;
