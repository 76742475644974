import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Footer from './Footer';

import ExternalRedirect from './components/ExternalRedirect'; // Importando de onde foi definido

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />

          {/* Redirecionamento externo */}
          <Route path='/spotify' element={<ExternalRedirect url="https://open.spotify.com/artist/3RUOoztK2hYVZrAbFztJlP?si=rB8qhOJIS9-7JC6zNjJ6yQ" />} />
          <Route path='/soundcloud' element={<ExternalRedirect url="https://soundcloud.com/lumiert" />} />
          <Route path='/github' element={<ExternalRedirect url="https://instagram.com/djlumiert" />} />
          <Route path='/linkedin' element={<ExternalRedirect url="https://br.linkedin.com/in/lumiert" />} />
	      <Route path='/discord' element={<ExternalRedirect url="https://discord.gg/tAZmPbUgGD" />} /> 
	      <Route path='/instagram' element={<ExternalRedirect url="https://instagram.com/djlumiert" />} />
	      <Route path='/youtube' element={<ExternalRedirect url="https://youtube.com/@BrenoLumiert" />} />
	      <Route path='/music' element={<ExternalRedirect url="https://www.youtube.com/@Lumiert" />} />
	</Routes>
      </Router>
      <Footer />  
    </div>
  );
}

export default App;
